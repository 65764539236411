<template>
  <div class="about">
    <h1 class="main_title text-center">{{ lang.legal_title }}</h1>
    <div v-if="lang.id == 'es'">
      <p>
        <strong>Editor:</strong><br />
        <a href="mailto:fga@cfdt.fr">Fabien GUIMBRETIERE</a>, Secretario General
      </p>
      <p>
        <strong>Dirección postal</strong><br />
        Sindicato agroalimentario Y Agrícola FGA-CFDT<br />
        47 Avenida Simón Bolívar,<br />
        75950 París
      </p>
      <h2>
        <strong>Menciones legales y términos y condiciones de uso</strong>
      </h2>
      <p>
        La CFDT implementa el tratamiento de datos de carácter personal como
        parte de su actividad y, en particular, a través de su sitio web
        https://www.salaire-fga-cfdt.fr/
      </p>
      <p>
        El CFDT se compromete a tratar todos los datos personales de acuerdo con
        la normativa vigente.
      </p>
      <p>
        Cada servicio en línea limita al mínimo la recopilación de datos
        personales (minimización de datos) y va acompañado de información sobre:
      </p>
      <ol>
        <li>
          el responsable del tratamiento y los objetivos de la recopilación de
          estos datos (finalidades)
        </li>
        <li>La base jurídica para el tratamiento de datos</li>
        <li>
          Recopilación de datos obligatoria u opcional para la gestión de su
          solicitud y la recuperación de las categorías de datos procesados;
        </li>
      </ol>
      <p>
        Las prácticas específicas descritas en esta declaración de política de
        privacidad se refieren únicamente al sitio web antes mencionado. Enlaces
        externos de otras estructuras profesionales relacionadas con el CFDT
        pueden estar presentes en nuestro sitio web. Le recomendamos que revise
        las declaraciones de política de privacidad de otras estructuras cfdt
        cuando visite sus sitios de Internet.
      </p>
      <p>&nbsp;</p>
      <h3><strong> 1. Propósitos del tratamiento</strong></h3>
      <p>
        Los datos personales se recopilan con fines específicos, explícitos y
        legítimos. El CFDT garantiza que los datos personales sean tratados de
        manera adecuada, relevante y limitada a lo necesario en términos de los
        fines para los que se procesan.
      </p>
      <p>Para los miembros de CFDT:</p>
      <p>
        Activa tu cuenta personal para acceder a tu contenido y servicios,
        actualizar tu información de contacto y ponerte en contacto con tu
        corresponsal local.<br />
        Proporcione información sobre las actividades de CFDT basadas en sus
        intereses nacionales, regionales o profesionales.<br />
        Responda a las preguntas específicas que abordó a través del formulario
        de contacto o póngase en contacto con el correo electrónico de nuestras
        federaciones o regiones a las que hace referencia en este sitio.<br />
        Suscríbete a los Boletines informativos ofrecidos en los distintos
        sitios.
      </p>
      <p>Para visitantes que no son CFDT:</p>
      <p>
        Procese su solicitud de CFDT completando el procedimiento en línea
        https://www.cfdt.fr/portail/adhesion/adherez-en-ligne<br />
        Responda a las preguntas específicas que abordó a través del formulario
        de contacto o póngase en contacto con el correo electrónico de nuestras
        federaciones o regiones a las que hace referencia en este sitio.<br />
        Suscríbete a los Boletines informativos ofrecidos en los distintos
        sitios.
      </p>
      <p>Para todos los visitantes:</p>
      <p>
        Responda a las preguntas específicas que aborda a través del formulario
        de contacto o correo electrónico de contacto de nuestras federaciones o
        regiones a las que hace referencia en este sitio<br />
        Establecer estadísticas de asistencia al sitio web
        https://www.salaire-fga-cfdt.fr/
      </p>
      <p>&nbsp;</p>
      <h3><strong> 2. Base jurídica para el tratamiento</strong></h3>
      <p>La base legal para los tratamientos de CFDT es:</p>
      <p>Los intereses legítimos perseguidos por el CFDT:</p>
      <ul>
        <li>
          Proporcionar información y servicios personalizados a los miembros de
          CFDT
        </li>
        <li>
          Facilitar los pasos de los miembros de cfdt con las diversas
          estructuras CFDT (confederación, federaciones, sindicatos
          interprofesionales regionales, sindicatos, etc.) evitando pedirles que
          escriban o informen la misma información o preguntas varias veces.
        </li>
        <li>
          Responder a solicitudes y preguntas de usuarios de Internet, miembros
          de CFDT o no
        </li>
        <li>
          Permitir la membresía en línea de nuevos miembros y el seguimiento de
          su archivo por las diversas estructuras CFDT
        </li>
        <li>
          Usos del sitio de estudio y necesidades de los visitantes para mejorar
          los servicios
        </li>
      </ul>
      <p>&nbsp;</p>
      <h3><strong> 3. Destinatarios de datos personales</strong></h3>
      <p>
        Todos los datos personales recopilados permanecen bajo el control
        exclusivo del CFDT y nunca se divulgan ni comercializan.
      </p>
      <p>
        Los destinatarios de los datos personales son los equipos y proveedores
        de CFDT que cumplen contractualmente con la normativa vigente
        responsable de su tratamiento.
      </p>
      <p>&nbsp;</p>
      <h3><strong> 4. Derechos de las personas</strong></h3>
      <p>De acuerdo con las disposiciones legales aplicables, usted tiene:</p>
      <ul>
        <li>derecho de acceso a sus datos personales,</li>
        <li>derecho a rectificarlos o borrarlos,</li>
        <li>el derecho a limitar el tratamiento al que está sujeto,</li>
        <li>derecho a oponerse al tratamiento.</li>
      </ul>
      <p>
        Si siente, después de ponerse en contacto con nosotros, que sus derechos
        no están siendo respetados, puede presentar una queja ante una autoridad
        de supervisión. La autoridad de control a cargo de los salarios
        realizados por la CFDT es la Comisión Nacional de Tecnologías de la
        Información y Libertades (CNIL).
      </p>
      <p>
        Usted puede, sujeto a la producción de una prueba válida de identidad,
        ejercer sus derechos poniéndose en contacto con nosotros a través de un
        correo electrónico dirigido a dpo@cfdt.fr o enviando una carta a DPO
        CFDT 4 Boulevard de la Villette 75954 Paris Cedex 19
      </p>
      <p><strong>Galletas</strong></p>
      <p>
        El CFDT puede decidir, con el propósito adecuado de utilizar su Sitio,
        colocar «&nbsp;cookies&nbsp;» en su computadora y acceder a ellas. Una
        cookie es un archivo pequeño, a menudo anónimo, que contiene datos,
        incluido un identificador único, transmitido desde el servidor de un
        sitio web a su navegador y almacenado en el disco duro de su ordenador.
      </p>
      <p><strong>Configuración de las cookies de su navegador</strong></p>
      <p>
        Puede configurar su navegador para que acepte todas las cookies enviadas
        por los sitios que visita, para rechazar sistemáticamente todas estas
        cookies o para preguntarle en cada oportunidad si desea o no aceptarlas.
      </p>
      <p>
        Cada navegador es diferente, por lo que le invitamos a comprobar la
        ayuda de su navegador para obtener más información sobre cómo configurar
        la aceptación de cookies.
      </p>
      <p><strong>Enlaces</strong></p>
      <p>
        El Sitio puede contener enlaces a otros sitios que CFDT no opera. El
        CFDT no puede de ninguna manera ser responsable de la provisión de estos
        enlaces permitiendo el acceso a estos sitios y fuentes externas, y no
        puede asumir ninguna responsabilidad por el contenido, anuncios,
        productos, servicios u otro material disponible en o desde estos sitios
        o fuentes externas que no son verificados ni aprobados por sus equipos.
      </p>
      <p><strong>Responsabilidad</strong></p>
      <p>
        El CFDT se compromete a hacer todo lo posible para proporcionar
        información actualizada y precisa. Sin embargo, no puede considerarse
        responsable de errores, omisiones o resultados que pudieran lograrse
        mediante el uso indebido de esta información.
      </p>
      <p>
        El CFDT hará los cambios necesarios tan pronto como estos errores sean
        puestos en su conocimiento; en general, puede modificar, en cualquier
        momento, sin previo aviso, la totalidad o parte del Sitio y sus términos
        de uso, sin responsabilidad.
      </p>
      <p>
        La descarga de todo el material al utilizar el servicio está a riesgo
        del usuario. Este último es el único responsable de cualquier daño
        sufrido por su ordenador o cualquier pérdida de datos resultante de esta
        descarga.
      </p>
      <p>
        En términos más generales, el CFDT no será responsable en ningún caso en
        caso de daños directos y/o indirectos resultantes del uso de este Sitio.
      </p>
      <p><strong>Disponibilidad</strong></p>
      <p>
        Es técnicamente imposible proporcionar el Sitio libre de cualquier
        defecto y estos defectos pueden conducir a la falta de disponibilidad
        temporal del Sitio. El funcionamiento del Sitio puede verse afectado por
        eventos y/o elementos que la CFDT no controla, como medios de
        transmisión y comunicación entre CFDT, usuarios del Sitio, redes,
        proveedores. En cualquier momento, el CFDT puede modificar o interrumpir
        temporal o permanentemente todo o parte del Sitio para realizar
        operaciones de mantenimiento y/o realizar mejoras y/o modificaciones en
        el Sitio. El CFDT informará, si es posible, de cada
        modificación/interrupción de los servicios disponibles en el Sitio. El
        CFDT no es responsable de las consecuencias de ninguna modificación,
        suspensión o interrupción del Sitio.
      </p>
      <p>&nbsp;</p>
      <h3><strong> 5. Anfitrión</strong></h3>
      <p>
        <strong>Nuxit<br /> </strong>Calle General Mangin 97, 38100 Grenoble
        <br /><a href="https://www.nuxit.com/">Accueil</a>
        <br />
        Nuestros sitios y aplicaciones están alojados en los servidores de Nuxit
        ubicados en Francia.
      </p>
      <p>&nbsp;</p>
      <hr />
      <p>&copy; 2021 CFDT - {{ lang.copyright }}</p>
    </div>
    <div v-if="lang.id == 'en'">
      <p class="text-center">
        Published on 08/04/2021<br />
        By Information Communication
      </p>
      <hr />

      <p>
        <strong>Editor:</strong><br />
        <a href="mailto:fga@cfdt.fr">Fabien GUIMBRETIERE</a>, Secretary General
      </p>
      <p>
        <strong>Postal address</strong><br />
        FGA-CFDT Agri-Food and Agriculture Trades Union<br />
        47 Simon Bolivar Avenue,<br />
        75950 Paris
      </p>
      <h2><strong>Legal mentions and terms and conditions of use</strong></h2>
      <p>
        The CFDT implements data processing of a personal nature as part of its
        activity and in particular via its website
        https://www.salaire-fga-cfdt.fr/
      </p>
      <p>
        The CFDT is committed to processing all personal data in accordance with
        existing regulations.
      </p>
      <p>
        Each online service limits the collection of personal data to the bare
        minimum (minimization of data) and is accompanied by information on:
      </p>
      <ol>
        <li>
          the person responsible for the processing and the objectives of
          collecting this data (finalities)
        </li>
        <li>The legal basis for data processing</li>
        <li>
          Mandatory or optional data collection for the management of your
          application and the recall of the categories of data processed;
        </li>
      </ol>
      <p>
        The specific practices described in this privacy policy statement relate
        only to the aforementioned website. External links from other
        professional structures related to the CFDT may be present on our
        website. We recommend that you review the privacy policy statements of
        other CFDT structures when you visit their Internet sites.
      </p>
      <p>&nbsp;</p>

      <h3><strong>1. Treatment purposes</strong></h3>

      <p>
        Personal data is collected for specific, explicit and legitimate
        purposes. The CFDT ensures that personal data is treated in an adequate,
        relevant and limited manner to what is necessary in terms of the
        purposes for which it is processed.
      </p>
      <p>For CFDT members:</p>
      <p>
        Activate your personal account to access your content and services,
        update your contact information and contact your local correspondent.
        <br />
        Provide information about CFDT’s activities based on your national,
        regional or professional interests.
        <br />
        Answer the specific questions you address through the contact form or
        contact email of our federations or regions referenced on this site.
        <br />
        Subscribe to the Newsletters offered on the various sites.
      </p>
      <p>For non-CFDT visitors:</p>
      <p>
        Process your CFDT application by completing the online procedure
        https://www.cfdt.fr/portail/adhesion/adherez-en-ligne
        <br />
        Answer the specific questions you address through the contact form or
        contact email of our federations or regions referenced on this site.
        <br />Subscribe to the Newsletters offered on the various sites.
      </p>
      <p>For all visitors:</p>
      <p>
        Answer the specific questions you address through the contact form or
        contact email of our federations or regions referenced on this site
        <br />
        Establish website attendance statistics https://www.salaire-fga-cfdt.fr/
      </p>

      <h3><strong>2. Legal basis for treatment</strong></h3>

      <p>The legal basis for CFDT’s treatments is:</p>
      <p>The legitimate interests pursued by the CFDT:</p>
      <ul>
        <li>Provide personalized information and services to CFDT members</li>
        <li>
          To facilitate the steps of CFDT members with the various CFDT
          structures (confederation, federations, regional inter-professional
          unions, trade unions, etc.) by avoiding asking them to write or inform
          the same information or questions several times.
        </li>
        <li>
          Respond to requests and questions from Internet users, CFDT members or
          not
        </li>
        <li>
          Allow online membership of new members and follow-up of their file by
          the various CFDT structures
        </li>
        <li>Study site uses and visitor needs to improve services</li>
      </ul>
      <p>&nbsp;</p>

      <h3><strong>3. Recipients of personal data</strong></h3>

      <p>
        All personal data collected remains under the exclusive control of the
        CFDT and is never disclosed or marketed.
      </p>
      <p>
        The recipients of the personal data are the CFDT teams and providers
        contractually compliant with the current regulations responsible for
        their processing.
      </p>
      <p>&nbsp;</p>

      <h3><strong>4. People’s rights</strong></h3>

      <p>In accordance with the applicable legal provisions, you have:</p>
      <ul>
        <li>a right of access to your personal data,</li>
        <li>right to rectify or erase them,</li>
        <li>the right to limit the treatment you are subject to,</li>
        <li>right to object to treatment.</li>
      </ul>
      <p>
        If you feel, after contacting us, that your rights are not being
        respected, you can file a complaint with a supervisory authority. The
        control authority in charge of salaries carried out by the CFDT is the
        National Commission for Information Technology and Freedoms (CNIL).
      </p>
      <p>
        You can, subject to the production of a valid proof of identity,
        exercise your rights by contacting us via an e-mail addressed to
        dpo@cfdt.fr or by sending a letter to DPO CFDT 4 Boulevard de la
        Villette 75954 Paris Cedex 19
      </p>

      <p><strong>Cookies</strong></p>
      <p>
        The CFDT may decide, for the proper purpose of using its Site, to place
        «&nbsp;cookies&nbsp;» on your computer and access them. A cookie is a
        small, often anonymous file containing data, including a unique
        identifier, transmitted from a website’s server to your browser and
        stored on your computer’s hard drive.
      </p>

      <p><strong>Setting your browser cookies</strong></p>
      <p>
        You can set up your browser to accept all cookies sent by the sites you
        visit, to systematically reject all these cookies, or to ask you at
        every opportunity whether or not you want to accept them.
      </p>
      <p>
        Each browser is different, so we invite you to check your browser’s help
        to learn more about how to set up cookie acceptance.
      </p>
      <p><strong>Links</strong></p>
      <p>
        The Site may contain links to other sites that CFDT does not operate.
        The CFDT cannot in any way be held responsible for the provision of
        these links allowing access to these external sites and sources, and
        cannot bear any responsibility for the content, advertisements,
        products, services or other material available on or from these external
        sites or sources that are neither verified nor approved by its teams.
      </p>

      <p><strong>Responsibility</strong></p>
      <p>
        The CFDT is committed to making every effort to provide up-to-date and
        accurate information. However, it cannot be held responsible for errors,
        omissions or results that could be achieved by misuse of this
        information.
      </p>
      <p>
        The CFDT will make the necessary changes as soon as these errors are
        brought to its attention; more generally, it may modify, at any time,
        without notice, all or part of the Site and its terms of use, without
        liability.
      </p>
      <p>
        The download of all material when using the service is at the user’s
        peril. The latter is solely responsible for any damage suffered by his
        computer or any data loss resulting from this download.
      </p>
      <p>
        More generally, the CFDT will not be liable in any case in case of
        direct and/or indirect damage resulting from the use of this Site.
      </p>

      <p><strong>Availability</strong></p>
      <p>
        It is technically impossible to provide the Site free of any defects and
        these defects can lead to the temporary unavailability of the Site. The
        operation of the Site may be affected by events and/or elements that the
        CFDT does not control, such as means of transmission and communication
        between CFDT, Site users, networks, suppliers. At any time, the CFDT may
        temporarily or permanently modify or interrupt all or part of the Site
        to perform maintenance operations and/or make improvements and/or
        modifications on the Site. The CFDT will inform, if possible, of each
        modification/interruption of the services available on the Site. The
        CFDT is not responsible for the consequences of any modification,
        suspension or interruption of the Site.
      </p>

      <h3><strong>5. Host</strong></h3>

      <p>
        <strong>Nuxit</strong><br />
        97 General Mangin Street, 38100 Grenoble<br />
        <a href="https://www.nuxit.com/">https://www.nuxit.com</a><br />
        Our sites and applications are hosted on Nuxit’s servers located in
        France.
      </p>
      <p>&nbsp;</p>
      <hr />
      <p>&copy; 2021 CFDT - {{ lang.copyright }}</p>
    </div>
    <div v-if="lang.id == 'fr'">
      <p class="text-center">
        Publié le 08/04/2021<br />
        Par Information Communication
      </p>
      <hr />
      <p>
        <strong>Directeur de publication :</strong><br />
        <a href="mailto:fga@cfdt.fr">Fabien GUIMBRETIERE</a>, secrétaire général
      </p>
      <p>
        <strong>Adresse postale</strong><br />
        FGA-CFDT Syndicat des métiers de l'agro-alimentaire et de
        l'agriculture<br />
        47 Avenue Simon Bolivar,<br />
        75950 Paris
      </p>

      <h2>Mentions légales et conditions générales d’utilisation</h2>

      <p>
        La CFDT met en œuvre des traitements de données à caractère personnel
        dans le cadre de son activité et notamment via son site internet
        https://www.salaire-fga-cfdt.fr/
      </p>

      <p>
        La CFDT s’engage à traiter toutes les données à caractère personnel
        conformément à la réglementation en vigueur.
      </p>

      <div>
        <div>
          Chaque service en ligne limite la collecte des données personnelles au
          strict nécessaire (minimisation des données) et s’accompagne d’une
          information sur :
        </div>
        <ol>
          <li>
            le responsable du traitement et les objectifs du recueil de ces
            données (finalités)
          </li>
          <li>la base juridique du traitement de données ;</li>
          <li>
            le caractère obligatoire ou facultatif du recueil des données pour
            la gestion de votre demande et le rappel des catégories de données
            traitées ;
          </li>
        </ol>
        <div>
          Les pratiques spécifiques décrites dans cette déclaration de politique
          de la vie privée ne concernent que le site Internet susmentionné. Des
          liens externes d’autres structures professionnelles liées à la CFDT
          peuvent être présents sur notre site. Nous vous recommandons
          d’examiner les déclarations de politique de la vie privée des autres
          structures CFDT lorsque vous visitez leur(s) site(s) Internet.
        </div>
        <p>&nbsp;</p>
        <h3>1. Finalités des traitements</h3>
        <div>
          Les données à caractère personnel sont collectées pour des finalités
          déterminées, explicites et légitimes. La CFDT veille à traiter les
          données à caractère personnel de manière adéquate, pertinente et
          limitée à ce qui est nécessaire au regard des finalités pour
          lesquelles elles sont traitées.
        </div>
        <div>Pour les adhérent·e·s CFDT :</div>
        <div></div>
        <div>
          Activer votre compte personnel pour accéder aux contenus et services
          qui lui vous sont réservés, mettre à jour ses coordonnées et contacter
          votre correspondant local.
        </div>
        <div>
          Communiquer des informations sur les activités de la CFDT en fonction
          de vos centres d’intérêts au niveau national, régional ou
          professionnel.
        </div>
        Répondre aux questions spécifiques que vous adressez par le biais du
        formulaire de contact ou mail de contact de nos fédérations ou régions
        référencées sur ce site.
        <div>
          Vous abonner aux Newsletters proposées sur les différents sites.
        </div>
        <div>Pour les visiteurs non- adhérent·e·s CFDT :</div>
        <div>
          Traiter votre demande d’adhésion à la CFDT en complétant la procédure
          en ligne https://www.cfdt.fr/portail/adhesion/adherez-en-ligne
        </div>
        Répondre aux questions spécifiques que vous adressez par le biais du
        formulaire de contact ou mail de contact de nos fédérations ou régions
        référencées sur ce site.
        <div>
          Vous abonner aux Newsletters proposées sur les différents sites.
        </div>
        <div>Pour l’ensemble des visiteurs :</div>
        <div>
          Répondre aux questions spécifiques que vous adressez par le biais du
          formulaire de contact ou mail de contact de nos fédérations ou régions
          référencées sur ce site
        </div>
        Etablir des statistiques de fréquentation du site internet
        https://www.salaire-fga-cfdt.fr/
      </div>
      <p>&nbsp;</p>
      <div>
        <h3>2. Bases juridiques des traitements</h3>
        <div>
          Les bases juridiques des traitements effectués par la CFDT sont :
        </div>
        <div>Les intérêts légitimes poursuivis par la CFDT :</div>
        <ul>
          <li>
            Fournir des informations et services personnalisés aux adhérents à
            la CFDT
          </li>
          <li>
            Faciliter les démarches des adhérents CFDT auprès des différentes
            structures CFDT (confédération, fédérations, unions régionales
            interprofessionnelles, syndicats…) en évitant de leur demander
            d’écrire ou renseigner plusieurs fois les mêmes informations ou
            questions.
          </li>
          <li>
            Répondre aux demandes et questions des internautes, adhérents à la
            CFDT ou non
          </li>
          <li>
            Permettre l’adhésion en ligne aux nouveaux adhérents et le suivi de
            leur dossier par les différentes structures CFDT
          </li>
          <li>
            Etudier les usages du site et les besoins des visiteurs afin
            d’améliorer les services
          </li>
        </ul>
        <p>&nbsp;</p>
        <h3>3. Destinataires des données personnelles</h3>
        <div>
          Toutes les données à caractère personnel collectées restent sous le
          contrôle exclusif de la CFDT et ne sont jamais communiquées ni
          commercialisées.
        </div>
        Les destinataires des données personnelles sont les équipes CFDT et
        prestataires contractuellement conformes à la règlementation en vigueur
        chargés de leur traitement.
      </div>
      <div>
        <div></div>
        <p>&nbsp;</p>
        <h3>4. Droits des personnes</h3>
        <div>
          Conformément aux dispositions légales en vigueur, vous disposez :
        </div>
        <ul>
          <li>d’un droit d’accès à vos données à caractère personnel,</li>
          <li>d’un droit à la rectification ou à l’effacement de celles-ci,</li>
          <li>
            du droit à la limitation du traitement dont vous faites l’objet,
          </li>
          <li>du droit de vous opposer au traitement.</li>
        </ul>
        <div>
          Si vous estimez, après nous avoir contactés, que vos droits ne sont
          pas respectés, vous pouvez adresser une réclamation auprès d’une
          autorité de contrôle. L’autorité de contrôle en charge des traitements
          réaliséspar la CFDT est la Commission Nationale de l’Informatique et
          des Libertés (CNIL).
        </div>
        <div>
          Vous pouvez, sous réserve de la production d’un justificatif
          d’identité valide, exercer vos droits en nous contactant par
          l’intermédiaire d’un message électronique adressé à dpo@cfdt.fr ou en
          adressant un courrier à DPO CFDT 4 boulevard de la Villette 75954
          Paris Cedex 19
        </div>
        <p>&nbsp;</p>
        <strong>Cookies</strong>
        <div>
          La CFDT peut décider, aux bonnes fins d'utilisation de son Site, de
          placer des « cookies » sur votre ordinateur et d'y accéder. Un cookie
          est un petit fichier, souvent anonyme, contenant des données,
          notamment un identifiant unique, transmis par le serveur d'un site web
          à votre navigateur et stocké sur le disque dur de votre ordinateur.
        </div>
        <p>&nbsp;</p>
        <div>
          <strong>Paramétrage relatif aux cookies de votre navigateur</strong>
        </div>
        <div>
          Vous pouvez configurer votre navigateur pour qu'il accepte tous les
          cookies envoyés par les sites que vous consultez, pour qu'il rejette
          systématiquement tous ces cookies ou pour qu'il vous demande à chaque
          occasion si vous souhaitez ou non les accepter.
        </div>
        <div>
          Chaque navigateur étant différent, nous vous invitons à consulter
          l'aide de votre navigateur pour en savoir plus sur la façon de
          paramétrer l'acceptation des cookies.
        </div>
      </div>
      <p>&nbsp;</p>
      <div>
        <div><strong>Liens</strong></div>
        <div>
          Le Site peut contenir des liens vers d'autres sites que la CFDT
          n'exploite pas. La CFDT ne peut en aucune manière être tenue
          responsable de la mise à disposition de ces liens permettant l'accès à
          ces sites et sources externes, et ne peut supporter aucune
          responsabilité quant aux contenus, publicités, produits, services ou
          tout autre matériel disponibles sur ou à partir de ces sites ou
          sources externes qui ne sont ni vérifiés ni approuvés par ses équipes.
        </div>
        <p>&nbsp;</p>
        <div><strong>Responsabilité</strong></div>
        <div>
          La CFDT s’engage à assurer tous ses efforts pour offrir des
          informations actualisées et exactes. Cependant, elle ne saurait être
          tenue pour responsable d'erreurs, d'omissions ou des résultats qui
          pourraient être obtenus par un mauvais usage de ces informations.
        </div>
        <div>
          La CFDT effectuera les modifications nécessaires, dès que ces erreurs
          sont portées à sa connaissance ; plus généralement, elle peut
          modifier, à tout moment, sans préavis, tout ou partie du Site ainsi
          que ses conditions d'utilisation, sans que sa responsabilité puisse
          être engagée de ce fait.
        </div>
        <div>
          Le téléchargement de tous matériels lors de l'utilisation du service
          est à aux risques et périls de l’utilisateur. Celui-ci est seul
          responsable pour tout dommage subi par son ordinateur ou toutes pertes
          de données consécutives à ce téléchargement.
        </div>
        <div>
          Plus généralement, la CFDT ne pourra en aucun cas être responsable en
          cas de dommages directs et/ou indirects résultant de l'utilisation de
          ce Site.
        </div>
        <p>&nbsp;</p>
        <div><strong>Disponibilité</strong></div>
        <div>
          Il est techniquement impossible de fournir le Site exempt de tout
          défaut et ces défauts peuvent conduire à l'indisponibilité temporaire
          du Site. Le fonctionnement du Site peut être affecté par des
          événements et/ou des éléments que la CFDT ne contrôle pas, tels que
          par exemple, des moyens de transmission et de communication entre la
          CFDT, les utilisateurs du Site, les réseaux, les fournisseurs. La CFDT
          peut à tout moment, modifier ou interrompre temporairement ou de façon
          permanente tout ou partie du Site pour effectuer des opérations de
          maintenance et/ou effectuer des améliorations et/ou des modifications
          sur le Site. La CFDT informera, si possible, de chaque
          modification/interruption des services disponibles sur le Site. La
          CFDT n’est pas responsable des conséquences de toute modification,
          suspension ou interruption du Site.
        </div>
        <div></div>
        <p>&nbsp;</p>
        <h3>5. Hébergeur</h3>
        <div><strong>Nuxit</strong></div>
        <div>
          <span class="LrzXr">97 Rue Général Mangin, 38100 Grenoble</span>
        </div>
        <div>https://www.nuxit.com/</div>
        <div>
          Nos sites et applications sont hébergés sur les serveurs de Nuxit
          localisés en France.
        </div>
        <p>&nbsp;</p>
        <hr />
        <p>&copy; 2021 CFDT - {{ lang.copyright }}</p>
      </div>
    </div>
  </div>
</template>
<style scoped>
.about {
  width: 100%;
}
</style>
<script>
export default {
  name: "legal",
  computed: {
    lang() {
      let langIndex = this.$store.state.lang;
      return this.$store.state.languages[langIndex];
    },
  },
};
</script>